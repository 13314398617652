<template lang="pug">
  .page-container.exam-slots
    portal(to="toolbar-title")
      toolbar-title Exam slots

    v-container(fluid data-test="results-page")
      portal(to='toolbar')
        v-toolbar-title(data-test="slots-title") Exam slots
      v-card
        actions
        filters(@filterChanged="updateList")
        list(
          :model="model"
          @loadMore="loadListMore"
          :loading="loading"
          :pagination="pagination"
          v-show="!loadingErr"
        ).pb-4
        .slots-error(v-show="loadingErr") {{ loadingErr }}


        v-btn(
          color="primary" fab @click="loadLogs" data-test="log-btn"
          v-if="log"
        ).btn-floating
          v-icon(color="white") mdi-post
        v-navigation-drawer(
          v-model="logShowing"
          fixed
          right
          touchless
          width="400"
          ref='drawer'
          v-if="log"
        )
          logs(:value="logShowing" @click:loadMore="loadLogsMore" :model="log" ref="log")
            template(v-slot:header="props")
              logTabs(:model="props.model" :loadData="props.loadData" :getDefaultPaginationParams="props.getDefaultPaginationParams")
            template(v-slot:candidateName="{candidate, fullName}")
              span.link  {{ fullName }}
              span.link  - {{ candidate.number }}
</template>

<script>
import {mapGetters} from "vuex"

import Svc from "./core/slots-service"
import SlotsModel from "./core/slots-model"
import errorsMixin from '@/mixins/errors.mixin'
import SlotsLogModel from "./core/models/slotsLogModel"
import { LOG_PAGE } from '@/util/const'
import PaginationModel from "@/models/paginationModel"
import paginationMixin from '@/mixins/pagination.mixin'
import permsMixin from '@/mixins/perms.mixin'

export default {
  mixins: [errorsMixin, paginationMixin, permsMixin],

  data: () => ({
    svc: new Svc(),
    model: new SlotsModel(),
    loading: false,
    loadingErr: null,
    pagination: null,
    log: null,
    logShowing: false
  }),

  inject: ['perms'],

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    }),
  },

  methods: {
    async initLogs(wsURL) {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.log = new SlotsLogModel(
        {page: [LOG_PAGE.TOP_CBR_SLOTS, LOG_PAGE.MIJN_CBR_SLOTS]},
        `${wsURL}?auth_token=${tokens.access}`
      )
      this.log.ws.addEventListener("message", this.listenLogs)
    },

    listenLogs(msg) {
      this.log.listen(msg)
    },

    updateList() {
      this.loadList({})
    },

    async loadListMore() {
      this.pagination.loadMore()
      await this.setPaginationQuery()
      this.loadList({more: true})
    },

    async loadList({more = false}) {
      try {
        this.loading = true
        this.loadingErr = null
        let res = await this.svc.list(this.$route.query)
        if(!more) {
          this.model.list = {}
          if(!Object.keys(res.results).length) this.loadingErr = 'According to your parameters, there are no free slots. Try another date, time, location or day.'
        }
        this.pagination = new PaginationModel(this.setPaginationParams(res.pagination))
        this.model.setList(res.results, this.$set)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    loadLogs() {
      if(this.logShowing) {
        this.logShowing = false
        return
      }
      this.$refs.log.loadData({
        ...this.$refs.log.getDefaultPaginationParams(),
      })
      this.logShowing = true
    },

    loadLogsMore() {
      this.$refs.log.loadMore({
        ...this.$refs.log.getPaginationParams(),
      })
    },
  },

  watch: {
    currentUser: {
      handler: function(newUserData) {
        if(newUserData && newUserData.logsWebsocketPath) this.initLogs(newUserData.logsWebsocketPath)
      },
      immediate: true
    }
  },

  provide: function() {
    return {
      svc: this.svc,
      getDefaultPagination: this.getDefaultPagination
    }
  },

  beforeRouteLeave(to, from , next) {
    if(this.log && this.log.ws) {
      this.log.ws.removeEventListener("message", this.listenLogs)
      this.log.ws.close()
    }
    next()
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    filters: () => import('./components/SlotsFilters.vue'),
    list: () => import('./components/SlotsList.vue'),
    actions: () => import('./components/SlotsActions.vue'),
    logs: () => import('@/app/admin/components/Log.vue'),
    logTabs: () => import('./components/SlotsLogTabs.vue')
  },
}
</script>

<style lang="scss" scoped>
.slots-error {
  padding: 18px;
  font-weight: bold;
}
</style>
