import WsInternal from "@/services/api/util/websocket-internal"
import LogItem from '@/app/admin/models/LogItemModel'
import Api from "@/services/api/api-activityLogs"

export default class SlotsLogModel extends WsInternal {
  constructor(params = {}, wsURL) {
    super(process.env.VUE_APP_CRM_WS + `${wsURL}`)
    this.items = []
    this.loading = false
    this.params = params
  }
  
  startListen(callback) {
    this.ws.onmessage = event => {
      const wsMessageData = this.getMsgData(event)
      if(!wsMessageData) return
      if(!wsMessageData.page) return
      if(!this.params.page.some(page => page === wsMessageData.page)) return
      this._addToItems(wsMessageData)
      if(callback) callback(wsMessageData)
    }
  }

  listen() {
    const wsMessageData = this.getMsgData(event)
    if(!wsMessageData) return
    if(!wsMessageData.page) return
    if(!this.params.page.some(page => page === wsMessageData.page)) return
    this._addToItems(wsMessageData)
    return wsMessageData
  }

  _addToItems(msg) {
    if(this.params && this.params.status && (this.params.status !== msg.status)) return
    this.items.unshift(new LogItem(msg))
  }

  async list(params, more) {
    try {
      params = {...params}
      let res = await Api.list(this._getParams(params))
      let items = res.results.map(item => new LogItem(item))
      if(more) this.items.push(...items)
      else this.items = items
      return res
    } catch (error) {
      console.log(error)
      this.loading = false
      return Promise.reject(error)
    }
  }

  _getParams(params) {
    let _params = params
    if(this.params) _params = {...params, ...this.params}
    return _params
  }

  close() {
    this.ws.close()
  }
}